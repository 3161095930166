.ad {
    border-radius: 3px;
    border: 1px solid #dbdbdb;
    margin-bottom: 15px;
    max-width: 600px;
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .ad {
        border-width: 0;
    }
}
