.search-input {
    width: 215px;
    height: 28px;

    background-color: #fafafa;
    color: #8e8e8e;

    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    padding-left: 80px;

    box-sizing: border-box;
    border: solid 1px #dbdbdb;
    border-radius: 3px;

    background-image: url("../../assets/SearchIcon.svg");
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: 65px 8px;

    transition: all 0.1s ease-in-out;

    &:focus {
        background-image: None;
        outline: none !important;
        background-position: 0;
        padding-left: 16px;
    }

    &::-webkit-search-cancel-button {
        -webkit-appearance: none;
        height: 13px;
        width: 13px;

        background-size: 13px;
        background-repeat: no-repeat;
        background-image: url("../../assets/ClearIcon.svg");
    }

    &::-webkit-calendar-picker-indicator {
        display: none;
        height: 0;
        width: 0;
        padding: 0;
        margin: 0;
        opacity: 0;
    }
}

@media only screen and (max-width: 600px) {
    .search-input {
        width: 100%;
        height: 30px;
        background-color: white;
        background-position: calc(50% - 27.5px - 10px) 9px;
        padding-left: calc(50% - 27.5px);
    }
}
