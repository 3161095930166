.celeb-info-container {
    border-bottom: 1px solid #dbdbdb;
    max-width: 600px;
    width: 100%;
    margin-bottom: 30px;
    padding-bottom: 20px;
    flex-direction: column;
    display: flex;
    justify-content: space-around;

    * {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .celeb-info {
        flex-direction: row;
        display: flex;
        justify-content: space-around;

        .celeb-avatar {
            min-width: 150px;
            height: 150px;
            vertical-align: middle;
            border-radius: 50%;
            margin-right: 45px;
            margin-left: 30px;
        }

        .celeb-data {
            flex-direction: column;
            display: flex;
            color: #262626;
            width: 100%;

            .celeb-name {
                font-weight: 300;
                line-height: 34px;
                font-size: 28px;
                margin: 0 20px 10px 0;
            }

            .follow-button-container-celeb-info {
                padding-top: 2px;
                margin-bottom: 20px;
                width: 210;
            }
        }
    }

    .celeb-insta-name {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    .celeb-num-videos {
        font-size: 14px;
        font-weight: 400;

        .celeb-num-videos-number {
            font-weight: 600;
        }
    }
}

@media only screen and (max-width: 600px) {
    .celeb-info-container {
        justify-content: space-around;
        margin: 0;
        padding: 16px;
        align-items: left;

        .celeb-info {
            .celeb-avatar {
                min-width: 80px;
                height: 80px;
                margin: 0;
                margin-right: 20px;
            }
        }

        .celeb-other-info-mobile {
            margin-left: 3px;
        }
    }
}
