.header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    height: 44px;
    overflow: hidden;
    border-bottom: 1px solid #dbdbdb;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;

    .header-main {
        padding: 0 16px;
        flex-direction: row;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;

        .back-button {
            background-image: url("../../assets/BackButtonIcon.svg");
            width: 24px;
            height: 24px;
            cursor: pointer;
            transform: rotate(270deg);

            transition: all 0.1s ease-in-out;

            &:active {
                opacity: 0.8;
            }
        }

        .message {
            line-height: 20px;
            font-weight: 600;
            font-size: 18px;
            margin: 0 auto;
            margin-top: 10px;
            position: relative;
        }

        .header-logo-h1 {
            margin: 0 auto;
            padding: 0;
            height: 100%;

            a {
                transition: all 0.1s ease-in-out;

                &:active {
                    opacity: 0.8;
                }

                .logo {
                    margin-top: 7px;
                    height: 35px;
                }
            }
        }
    }
}
