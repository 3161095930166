.follow-button {
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    line-height: 26px;
    height: 30px;
    width: 100%;
    max-width: 90px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &:active {
        opacity: 0.8;
    }
}

.celeb-info-follow-button {
    max-width: 100px;
}

.unfollowing {
    background-color: #0095f6;
    border-color: #0095f6;
    color: white;
}

.following {
    background-color: transparent;
    border-color: #dbdbdb;
    color: #262626;
}

@media only screen and (max-width: 600px) {
    .celeb-info-follow-button {
        max-width: 210px;
    }
}
