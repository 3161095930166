.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    height: 54px;
    overflow: hidden;
    border-bottom: 1px solid #dbdbdb;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;

    .main-nav {
        max-width: 975px;
        flex-direction: row;
        display: flex;
        align-items: center;
        width: 100%;

        .box {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            .navbar-logo-h1 {
                padding: 0;
                margin: 0;
                height: 100%;

                .logo {
                    cursor: pointer;
                    height: 42px;
                    max-width: 100%;
                    margin-top: 7px;
                    margin-right: auto;
                }
            }

            .navbar-tabs {
                flex-direction: row;
                display: flex;
                align-items: center;
                width: 88px;
                margin-left: auto;

                img {
                    height: 24px;
                    width: 24px;
                    margin-right: 24px;
                    cursor: pointer;
                }
            }
        }
    }
}
