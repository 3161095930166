.tabs {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    height: 44px;
    overflow: hidden;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #dbdbdb;

    .tabs-main {
        flex-direction: row;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-around;
        height: 100%;

        a {
            width: 100%;
            height: 100%;
            transition: all 0.1s ease-in-out;

            &:active {
                opacity: 0.8;
            }

            .tab {
                display: flex;
                align-items: center;
                height: 100%;
                justify-content: center;

                img {
                    height: 24px;
                    width: 24px;
                    cursor: pointer;
                }
            }
        }
    }
}
