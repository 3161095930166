.video {
    border-radius: 3px;
    border: 1px solid #dbdbdb;
    margin-bottom: 15px;
    max-width: 600px;
    width: 100%;
    background-color: white;

    * {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .video-head {
        height: 60px;
        padding: 12px 16px;
        flex-direction: row;
        align-items: center;
        display: flex;

        .celeb-avatar {
            vertical-align: middle;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            margin-right: 10px;
            cursor: pointer;
        }

        .celeb-names {
            flex-direction: column;
            color: #262626;
            cursor: pointer;

            .celeb-name {
                font-weight: 300;
                line-height: 20px;
                font-size: 16px;
                transition: all 0.1s ease-in-out;

                &:active {
                    opacity: 0.8;
                }
            }

            .celeb-insta-name {
                font-weight: 600;
                font-size: 13px;
                line-height: 16px;
                transition: all 0.1s ease-in-out;

                &:active {
                    opacity: 0.8;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .follow-button-container-video {
            margin-left: auto;
            width: 90px;
        }
    }

    .video-frame {
        position: relative;
        padding-bottom: 124.933%;
        margin-bottom: 0;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .video-bottom {
        padding: 5px 16px;

        .time {
            text-transform: uppercase;
            color: #8e8e8e;
            font-size: 10px;
            line-height: 17px;
            letter-spacing: 0.2px;
        }
    }
}

@media only screen and (max-width: 600px) {
    .video {
        border-width: 0;

        .video-head {
            padding: 12px 16px;
        }
    }
}
