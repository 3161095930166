:root {
    background-color: #fafafa;
    font-family: apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
        Arial, sans-serif;
    -webkit-tap-highlight-color: transparent;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
}

.main {
    margin-top: 54px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

a {
    text-decoration: inherit;
    color: inherit;
}

.video-plus-ad {
    width: 100%;
    max-width: 600px;
}

@media only screen and (max-width: 600px) {
    :root {
        background-color: white;
    }

    body {
        width: 100%;
    }

    .main {
        margin-top: 44px;
        margin-bottom: 44px;
        padding-top: 0;
    }
}
