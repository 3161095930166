.search-result {
    padding: 8px 16px;
    height: 60px;
    cursor: pointer;
    flex-direction: row;
    display: flex;
    width: 100%;

    * {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    a {
        width: 100%;
        height: 100%;
        transition: all 0.1s ease-in-out;
        flex-direction: row;
        align-items: center;
        display: flex;

        &:active {
            opacity: 0.8;
        }

        .celeb-avatar {
            vertical-align: middle;
            width: 44px;
            height: 44px;
            border-radius: 50%;
            margin-right: 12px;
        }

        .celeb-names {
            flex-direction: column;
            color: #262626;

            .celeb-name {
                font-weight: 300;
                line-height: 20px;
                font-size: 16px;
                transition: all 0.1s ease-in-out;
            }

            .celeb-insta-name {
                font-weight: 600;
                font-size: 13px;
                line-height: 16px;
                transition: all 0.1s ease-in-out;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
